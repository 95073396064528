<template>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessRepresentative"
      v-if="business.representative != undefined"
    >
      <app-form-input
        id="business.representative.first_name"
        name="business.representative.first_name"
        type="text"
        :placeholder="$t('business.representative.first_name_placeholder')"
        :labelText="$t('business.representative.legal_name')"
        v-model="business.representative.first_name"
        :errorMessage="errors.first_name"
        :disabled="editMode"
      />
      <app-form-input
        id="business.representative.last_name"
        name="business.representative.last_name"
        type="text"
        :placeholder="$t('business.representative.last_name_placeholder')"
        class="mb-5"
        v-model="business.representative.last_name"
        :errorMessage="errors.last_name"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.email"
        name="business.representative.email"
        type="email"
        :labelText="$t('business.representative.email')"
        class="w-full mb-5"
        v-model="business.representative.email"
        :errorMessage="errors.email"
        :disabled="editMode"
      />

      <app-form-input
          id="business.representative.designation"
          name="business.representative.designation"
          type="text"
          class="w-full mb-5"
          :placeholder="$t('business.representative.job_position_placeholder')"
          :labelText="$t('business.representative.designation')"
          v-model="business.representative.designation"
          :errorMessage="errors.designation"
          :disabled="editMode"
        />

      <app-form-input
        type="date"
        :labelText="$t('business.representative.date_of_birth')"
        :placeholder="$t('business.representative.date_placeholder')"
        class="w-full mb-5"
        v-model="business.representative.date_of_birth"
        :errorMessage="errors.date_of_birth"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.address_1"
        name="business.representative.address_1"
        type="text"
        :placeholder="$t('business.representative.address_placeholder_1')"
        :labelText="$t('business.representative.home_address')"
        class="w-full mb-1"
        v-model="business.representative.address_1"
        :errorMessage="errors.address_1"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.address_2"
        name="business.representative.address_2"
        type="text"
        :placeholder="$t('business.representative.address_placeholder_2')"
        class="w-full mb-1"
        v-model="business.representative.address_2"
        :errorMessage="errors.address_2"
        :disabled="editMode"
      />

      <app-form-input
        id="business.representative.postcode"
        name="business.representative.postcode"
        type="number"
        :placeholder="$t('business.representative.postcode_placeholder')"
        class="w-full mb-1"
        v-model="business.representative.postcode"
        :errorMessage="errors.postcode"
        :disabled="editMode"
        :numberOnly="true"
      />

      <app-form-input
        id="business.representative.city"
        name="business.representative.city"
        type="text"
        :placeholder="$t('business.representative.city_placeholder')"
        class="w-full mb-1"
        v-model="business.representative.city"
        :errorMessage="errors.city"
        :disabled="editMode"
      />
    
      <app-form-country
        :allowFilter="true"
        :showLabel="false"
        :placeholder="$t('general.country')"
        class="w-full mb-1"
        v-model="business.representative.country"
        :errorMessage="errors.country_id"
        :disabled="editMode"
      />

      <app-form-state
        :showLabel="false"
        class="w-full mb-5"
        :placeholder="$t('general.address.state')"
        :country_id="business.representative.country?.id ?? 0"
        v-model="business.representative.state"
        :errorMessage="errors.state_id"
        :disabled="editMode"
      />

      <app-form-telephone-no
        name="business.representative.phone_no"
        :labelText="$t('business.representative.phone_no')"
        class="w-full mb-5"
        v-model="business.representative.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="editMode"
      />

      <div class="lg:w-7/12 w-full mb-3 text-sm">
        <label class="inline-flex items-center">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="MyKad"
            v-model="business.representative.identification_type"
            checked
            :disabled="editMode"
          />
          <span class="ml-2">{{ $t("business.representative.mykad") }}</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="Passport"
            v-model="business.representative.identification_type"
            :disabled="editMode"
          />
          <span class="ml-2">{{ $t("business.representative.passport") }}</span>
        </label>
      </div>

      <div class="flex flex-col w-full mb-10">
        <app-form-input
          id="business.representative.identification"
          name="business.representative.identification"
          :labelText="labelIdentification()"
          v-model="business.representative.identification"
          :errorMessage="errors.identification"
          :type="ic_type_input.type"
          :maxlength="ic_type_input.maxlength"
          :disabled="editMode"
          :numberOnly="ic_type_input.type === 'number'"
        />
      </div>

      <app-button
        type="submit"
        v-if="!editMode"
        :showf70Icon="false"
        class="mt-3 w-full"
        :disabled="!hasChanged"
        :loading="loading"
      >
        {{ $t("general.save") }}
      </app-button>

      <app-button-outline class="mt-3 w-full" @onClick="editAction">
        {{ editMode ? $t("general.edit_information") : $t("general.cancel") }}
      </app-button-outline>
    </form>
  </div>
</template>

<script>
export default {
  props: ["menu_action", "edit_mode"],

  data() {
    return {
      hasChanged: false,
      ic_type: "MyKad",
      ic_type_input: null,
      emptyErrors: {
        full_name: [],
        email: [],
        date_of_birth: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        country_id: [],
        state_id: [],
        phone_no: [],
        identification_type: [],
        identification: [],
      },
    };
  },

  watch: {
    business: {
      handler: function () {
        this.hasChanged = true;
      },
      deep: true,
    },
    ic_type() {
      this.labelIdentification();
    },
  },

  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },

    business() {
      return this.$store.getters["businessStore/business"];
    },

    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    editMode: {
      get() {
        return this.edit_mode;
      },
      set(v) {
        this.$emit("update:edit_mode", v);
      },
    },
  },
  mounted(){
    this.selectInputType();
  },
  methods: {
    onSelected(event) {
      this.ic_type = event.target.value;
      this.business.representative.identification = null;
      this.selectInputType();
    },
    selectInputType() {
      switch (this.ic_type) {
        case "Passport":
          this.ic_type_input = {
            type: "text",
            maxlength: null,
          };
          break;
        case "MyKad":
        default:
          this.ic_type_input = {
            type: "number",
            maxlength: 12,
          };
      }
    },
    labelIdentification() {
      return this.ic_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },
    async submitBusinessRepresentative() {
      this.resetState();

      const businessRepresentative = await this.$store.dispatch(
        "businessStore/createOrUpdateBusinessRepresentative",
        this.business.representative
      );

      if (businessRepresentative != null) {
        this.hasChanged = false;
        this.$notify(
          {
            group: "success",
            title: "Saved!",
          },
          3000
        );
      }
    },
    editAction() {
      this.editMode = !this.editMode;

      if (this.editMode == false) {
        this.$store.dispatch(
          "businessStore/getBusinessById",
          this.$route.params.business_id
        );
      }
    },
    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>
